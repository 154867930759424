import {Box, Button, Checkbox, Colors, Icon, MenuItem, Select} from '@dagster-io/ui-components';
import React from 'react';
import styled from 'styled-components';

import {Input, SubmitButton} from '../components/button';
import {Card} from '../components/card';
import {Emphasized, Subtext, Title} from '../components/typography';
import {USER_PROFILE_PAGE_DATA, usePageData} from '../hooks/usePageData';

const TITLES = [
  'Analytics Engineer',
  'Data Analyst',
  'Data Engineer',
  'Data Scientist',
  'Data Platform Engineer',
  'ML Engineer',
  'Product Manager',
  'Software Engineer',
  'Other',
];

const HEARD = [
  'Conferences',
  'Dagster blog or event',
  'Data industry newsletters',
  'Google',
  'Podcasts',
  'Technical consultants',
  'My team',
  'Other',
];

export const UserProfile = () => {
  const {profile} = usePageData<USER_PROFILE_PAGE_DATA>();

  const [first, setFirst] = React.useState(profile.firstName);
  const [last, setLast] = React.useState(profile.lastName);
  const [heard, setHeard] = React.useState('');
  const [heardOther, setHeardOther] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [titleOther, setTitleOther] = React.useState('');

  const [tosAgreement, setTOSAgreement] = React.useState(false);

  const continueDisabled = !first || !last || !tosAgreement || !heard;

  const isOther = title === 'Other';
  const isHeardOther = heard === 'Other';

  const params = React.useMemo(() => new URLSearchParams(window.location.search), []);
  return (
    <>
      <Subtext as={Box} style={{marginBottom: 16}} flex={{direction: 'column', gap: 6}}>
        <Emphasized>Signed in as</Emphasized>
        <div>({profile.email})</div>
      </Subtext>
      <Wrapper as={Card} flex={{direction: 'column', gap: 12}}>
        <Box flex={{direction: 'column', gap: 8}}>
          <Title subtext={<Subtext>Tell us a bit more about yourself</Subtext>}>
            Finish your profile
          </Title>
        </Box>
        <InputsContainer>
          <div>Name*</div>
          <NameInputsWrapper flex={{direction: 'row', gap: 8}}>
            <Input
              placeholder="First"
              value={first}
              onChange={(e) => {
                setFirst(e.target.value);
              }}
            />
            <Input
              placeholder="Last"
              value={last}
              onChange={(e) => {
                setLast(e.target.value);
              }}
            />
          </NameInputsWrapper>
          <div>Job Title</div>
          <Box flex={{direction: 'column', gap: 8}}>
            <Select<string>
              filterable={false}
              items={TITLES}
              itemRenderer={(item, props) => (
                <MenuItem key={item} text={item} onClick={props.handleClick} />
              )}
              onItemSelect={(title) => {
                setTitleOther(title === 'Other' ? titleOther : '');
                setTitle(title);
              }}
              popoverProps={{targetTagName: 'div'}}
            >
              {isOther ? (
                <Button rightIcon={<Icon name="arrow_drop_down" />}>Other</Button>
              ) : (
                <Button rightIcon={<Icon name="arrow_drop_down" />}>{title || 'Select one'}</Button>
              )}
            </Select>
            {isOther ? (
              <Input
                placeholder="Job title"
                value={titleOther}
                onChange={(e) => {
                  setTitleOther(e.target.value);
                }}
              />
            ) : null}
          </Box>
          <div>How did you hear about Dagster?*</div>
          <Box flex={{direction: 'column', gap: 8}}>
            <Select<string>
              filterable={false}
              items={HEARD}
              itemRenderer={(item, props) => (
                <MenuItem key={item} text={item} onClick={props.handleClick} />
              )}
              onItemSelect={(heard) => {
                setHeardOther(heard === 'Other' ? heardOther : '');
                setHeard(heard);
              }}
              popoverProps={{targetTagName: 'div'}}
            >
              <Button rightIcon={<Icon name="arrow_drop_down" />}>{heard || 'Select one'}</Button>
            </Select>
            {isHeardOther ? (
              <Input
                placeholder="Other"
                value={heardOther}
                onChange={(e) => {
                  setHeardOther(e.target.value);
                }}
              />
            ) : null}
          </Box>
          <Checkbox
            label={
              <span data-cy="tos-checkbox">
                I agree to the Dagster+{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://dagster.io/terms"
                  style={{
                    color: Colors.Link,
                  }}
                >
                  terms of service
                </a>
                .
              </span>
            }
            size="small"
            checked={tosAgreement}
            fillColor={Colors.Blue500}
            onChange={() => setTOSAgreement(!tosAgreement)}
            format="check"
          />
        </InputsContainer>
        <form
          method="POST"
          action={`/user-profile/submit?organization_public_id=${params.get(
            'organization_public_id',
          )}&next=${params.get('next')}`}
        >
          <SubmitButton disabled={continueDisabled} type="submit">
            Continue
          </SubmitButton>
          <input type="hidden" name="first_name" value={first} />
          <input type="hidden" name="last_name" value={last} />
          <input type="hidden" name="heard" value={heardOther || heard} />
          <input type="hidden" name="title" value={titleOther || title} />
          <input type="hidden" name="tos" value={tosAgreement ? '1' : ''} />
        </form>
      </Wrapper>
    </>
  );
};

const NameInputsWrapper = styled(Box)`
  > * {
    flex: 50%;
  }
`;

const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  text-align: left;
`;

const Wrapper = styled(Box)`
  .bp4-popover-target {
    width: 100%;
  }

  button,
  input {
    &:not([type='checkbox']):not([type='submit']) {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr auto;
      text-align: left;
      height: 48px;
    }
  }

  [type='submit'] {
    text-align: center;
  }
`;
