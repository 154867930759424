import React from 'react';

import {Button} from './button';
import {OrgLoginData} from '../hooks/usePageData';

interface Props {
  org: OrgLoginData;
  next?: string | null;
  signup?: boolean;
}

export const OrganizationLoginButton = ({org, next, signup}: Props) => {
  const {status} = org;
  const statusLabel = React.useMemo(() => {
    if (status === 'ACTIVE') {
      return null;
    }

    const statusString = status.replace(/[^a-zA-Z]/g, ' ');
    return <span style={{textTransform: 'lowercase'}}>&nbsp;({statusString})</span>;
  }, [status]);

  return (
    <Button
      data-cy={`button-${org.id}`}
      key={org.id}
      as="a"
      href={`/auth/select-org/${org.id}?next=${next}${signup ? '&signup=1' : ''}`}
      $disabled={status !== 'ACTIVE' && status !== 'READ_ONLY'}
    >
      {org.name}
      {statusLabel}
    </Button>
  );
};
