import {Box, Colors, FontFamily, TextInput} from '@dagster-io/ui-components';
import React, {useState} from 'react';
import styled from 'styled-components';

import {SubmitButton} from './button';
import {Title} from './typography';

type Props = {
  onCreateOrganization: (organization: string) => void;
  checkUrl: string;
};

export const isValid = (name: string) => {
  // Cant be longer than 63 characters due to  https://en.wikipedia.org/wiki/Subdomain#Overview
  if (name.length > 63) {
    return false;
  }
  // Must be all lower case
  if (name.toLowerCase() !== name) {
    return false;
  }
  // Cant start or end with hyphen
  if (name.endsWith('-') || name.startsWith('-')) {
    return false;
  }

  // Has to start with letter
  if (!/^[a-z]+$/.test(name[0]!)) {
    return false;
  }
  // Can include numbers
  return /^[a-z0-9]+$/.test(name.replace(/-/g, ''));
};

export function NewOrganizationPrompt({onCreateOrganization, checkUrl}: Props) {
  const [organization, setOrganization] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const validateName = (name: string) => {
    isValid(name) || name === ''
      ? setError('')
      : setError(
          'Invalid organization name, only lowercase alphanumeric characters and hyphens allowed',
        );
  };

  const disabled = !!error.length || loading || !organization.length;

  const submitOrganization = async (organizationName: string) => {
    if (disabled) {
      return;
    }
    setLoading(true);
    const url = new URL(checkUrl, window.location.origin);
    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        organization_name: organizationName,
      }),
    };
    try {
      const response = await fetch(url.toString(), requestOptions);
      const {valid} = await response.json();
      if (!valid) {
        setError('Organization name is already taken');
        setLoading(false);
        return;
      } else {
        onCreateOrganization(organizationName);
      }
      setError('');
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  };

  return (
    <>
      <Title>Create an organization</Title>
      <Box flex={{direction: 'column', gap: 12}} style={{textAlign: 'left'}}>
        <Prompt>Organization name</Prompt>
        {error ? <Errors>{error}</Errors> : null}
        <SubDomainInput
          data-cy="subdomain_input"
          value={organization}
          placeholder="hooli"
          onChange={(event) => {
            validateName(event.target.value);
            setOrganization(event.target.value);
          }}
          onKeyDown={(event) => event.key === 'Enter' && submitOrganization(organization)}
        />
        <SubmitButton
          onClick={() => submitOrganization(organization)}
          style={{
            marginTop: 10,
          }}
          disabled={disabled}
        >
          {loading ? 'Submitting...' : 'Continue'}
        </SubmitButton>
      </Box>
    </>
  );
}

const Errors = styled.div`
  margin-bottom: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  font-weight: 400;
  color: ${Colors.Red500}
  padding: 4px 0px;
`;

const Prompt = styled.div`
  margin-bottom: 5px;
  font-family: ${FontFamily.default};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-weight: 400;
`;

const SubDomainInput = styled(TextInput)`
  font-family: ${FontFamily.default};
  border: none;
  width: 100%;

  line-height: 20px;
  margin-bottom: 10px;
  height: 48px;
  padding: 6px 12px 6px 16px;
  font-size: 16px;

  background-color: transparent;

  border-radius: 8px;
  box-shadow:
    rgba(189, 186, 183, 1) inset 0px 0px 0px 1px,
    rgba(233, 232, 232, 1) inset 2px 2px 1.5px;
  transition: box-shadow 150ms;

  &:focus {
    box-shadow:
      rgba(189, 186, 183, 1) inset 0px 0px 0px 1px,
      rgba(233, 232, 232, 1) inset 2px 2px 1.5px,
      rgba(58, 151, 212, 0.6) 0 0 0 3px;
    outline: none !important;
  }
`;
