import {Buffer} from 'buffer';

import {Box, Colors} from '@dagster-io/ui-components';
import React from 'react';

import {Button} from '../components/button';
import {Card} from '../components/card';

export function SigninEmailLanding() {
  const params = React.useMemo(() => new URLSearchParams(window.location.search), []);
  const [token, next] = React.useMemo(() => {
    try {
      return [
        Buffer.from(params.get('token')!, 'base64').toString('ascii'),
        Buffer.from(params.get('next')!, 'base64').toString('ascii'),
      ];
    } catch (_) {
      return ['', '/'];
    }
  }, [params]);
  return (
    <Card title="Sign in to Dagster+" flex={{direction: 'column', gap: 10}}>
      <Box flex={{direction: 'column', gap: 6}}>
        <Button
          key={token}
          as="a"
          href={`/signin/verify_email?token=${params.get('token')}&next=${next}`}
          style={{color: Colors.Dark}}
        >
          Sign in
        </Button>
      </Box>
    </Card>
  );
}
