import {Box, Colors, Spinner} from '@dagster-io/ui-components';
import React from 'react';
import {useSearchParams} from 'react-router-dom';
import styled from 'styled-components';

import {Card} from '../components/card';
import {EmailSignIn} from '../components/emailSignIn';
import {SigninCallout} from '../components/signinCallout';
import {SignUpButton, SignUpProvider} from '../components/signupButton';
import {TextSurroundedByLines} from '../components/textSurroundedByLines';
import {Emphasized, Subtext, Title} from '../components/typography';

export function Signup() {
  const [submitting, setSubmitting] = React.useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') || '';
  return (
    <>
      <SigninCallout />
      <Card flex={{direction: 'column', gap: 4}} margin={{bottom: 24}}>
        <Title subtext={<Subtext style={{marginBottom: '25px'}}>Try it free for 30 days</Subtext>}>
          Sign up for Dagster+
        </Title>
        {submitting ? <Spinner purpose="page" /> : null}
        <Box
          flex={{direction: 'column', gap: 6}}
          style={{display: submitting ? 'none' : undefined}}
        >
          <SignUpButton
            provider={SignUpProvider.google}
            setSubmitting={() => setSubmitting(true)}
          />
          <SignUpButton
            provider={SignUpProvider.github}
            setSubmitting={() => setSubmitting(true)}
          />
          <TextSurroundedByLines>or</TextSurroundedByLines>
          <EmailSignIn
            submitUrl={`/signup/email?token=${token}`}
            submitMessage="Check your inbox for a sign up link"
            buttonText="Sign up with email"
            recaptchaNotice={
              <RecaptchaNotice>
                This site is protected by reCAPTCHA and the Google{' '}
                <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
                <a href="https://policies.google.com/terms">Terms of Service</a> apply.
              </RecaptchaNotice>
            }
          />
        </Box>
      </Card>
      <Box flex={{direction: 'column', gap: 6}} margin={{bottom: 48}}>
        <Emphasized>New to Dagster?</Emphasized>
        <Subtext
          as="a"
          href="https://dagster.io/dagster-demo-signup"
          target="_blank"
          style={{textAlign: 'center', color: Colors.Link}}
        >
          Sign up for a live demo
        </Subtext>
      </Box>
    </>
  );
}

const RecaptchaNotice = styled.div`
  &,
  && a {
    color: ${Colors.Gray500};
  }
  font-size: 12px;
`;
