import {Box} from '@dagster-io/ui-components';
import React from 'react';
import {Link, useSearchParams} from 'react-router-dom';

import {OrganizationLoginButton} from '../components/OrganizationLoginButton';
import {Button} from '../components/button';
import {Card} from '../components/card';
import {NewOrganizationPrompt} from '../components/newOrganizationPrompt';
import {TextSurroundedByLines} from '../components/textSurroundedByLines';
import {Emphasized, Subtext} from '../components/typography';
import {SIGNUP_ORGANIZATION_PAGE_DATA, usePageData} from '../hooks/usePageData';
import {withSeon} from '../hooks/withSeon';

const IP_FETCH_ADDRESS = 'https://qd5j6yh3hslqrdgkmqj7c3zdyy0zroug.lambda-url.us-west-2.on.aws/';

const SIGNUP_INFO_COOKIE = 'dagster-cloud-signup-info';
const COOKIES = Object.fromEntries(
  document.cookie.split('; ').map((v) => v.split(/=(.*)/s).map(decodeURIComponent)),
);

export function SignupOrganization() {
  const [searchParams] = useSearchParams();
  const [body, setBody] = React.useState<Record<string, string> | null>(null);

  const genIpResponse = React.useMemo(
    () =>
      new Promise(async (res: (arg: any) => void) => {
        try {
          const response = await fetch(IP_FETCH_ADDRESS);
          const json = await response.json();
          res(json['result']);
        } catch (e) {
          res(null);
        }
      }),
    [],
  );

  const genSeonFingerprint = React.useMemo(
    () =>
      new Promise(async (res: (arg: any) => void) => {
        try {
          const seon = await withSeon();
          await new Promise((res) => {
            seon.config({
              host: window.location.host,
              session_id: COOKIES[SIGNUP_INFO_COOKIE],
              audio_fingerprint: true,
              canvas_fingerprint: true,
              webgl_fingerprint: true,
              onSuccess: res,
            });
          });
          const seon_fingerprint = await seon.getBase64Session();
          res(seon_fingerprint);
        } catch (e) {
          console.error(e);
          res(null);
        }
      }),
    [],
  );

  const createOrganization = async (organization: string) => {
    const [ip_response, seon_fingerprint] = await Promise.all([genIpResponse, genSeonFingerprint]);
    setBody({
      organization_name: organization,
      ip_response,
      seon_fingerprint,
    });
  };

  const formRef = React.useRef<HTMLFormElement>(null);
  React.useEffect(() => {
    if (body) {
      formRef.current && formRef.current.submit();
    }
  }, [body]);

  const [showCreateOrg, setShowCreateOrg] = React.useState(false);

  const {organizations, email} = usePageData<SIGNUP_ORGANIZATION_PAGE_DATA>();

  function content() {
    return (
      <Card flex={{direction: 'column', gap: 6}}>
        {!organizations.length || showCreateOrg ? (
          <NewOrganizationPrompt
            onCreateOrganization={createOrganization}
            checkUrl="/signup/organization/check"
          />
        ) : (
          <>
            <Button
              data-cy="button-create-org"
              onClick={() => {
                setShowCreateOrg(true);
              }}
            >
              Create a new organization
            </Button>
            <TextSurroundedByLines>or</TextSurroundedByLines>
            <Box flex={{direction: 'column', gap: 10}}>
              <Emphasized>Select an existing organization</Emphasized>
              <Box flex={{direction: 'column', gap: 6}}>
                {organizations.map((org) => (
                  <OrganizationLoginButton
                    key={org.id}
                    org={org}
                    next={searchParams.get('next')}
                    signup={true}
                  />
                ))}
              </Box>
            </Box>
          </>
        )}
      </Card>
    );
  }

  const queryString = React.useMemo(() => {
    return new URLSearchParams(window.location.search).toString();
  }, []);

  return (
    <Box flex={{direction: 'column', gap: 24}}>
      <Subtext as={Box} style={{marginBottom: 16}} flex={{direction: 'column', gap: 6}}>
        <Emphasized>Signed in as</Emphasized>
        <div>({email})</div>
      </Subtext>
      {content()}
      <Link to="/">Back to sign in</Link>
      {body ? (
        <form method="POST" action={`/signup/create?${queryString}`} ref={formRef}>
          {Object.keys(body).map((key) => (
            <input type="hidden" name={key} value={body[key]} key={key} />
          ))}
        </form>
      ) : null}
    </Box>
  );
}
