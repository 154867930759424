import memoize from 'lodash/memoize';

import type {PAGE_DATA} from './hooks/usePageData';

export const CLOUD_PORTAL_SETTINGS_ELEMENT_ID = 'cloud-portal-settings';

/**
 * To add a new value to injected JSON configuration, add a key/value to `ExtractedConfig`
 * and a placeholder that will be replaced on the server.
 */

export type ExtractedConfig = {
  recaptchaSiteKey: string;
  segmentWriteKey: string;
  segmentCDNProxy: string;
  pageData: PAGE_DATA;
};

const placeholders: Record<keyof ExtractedConfig, string> = {
  recaptchaSiteKey: '__RECAPTCHA_SITE_KEY__',
  segmentWriteKey: '__SEGMENT_WRITE_KEY__',
  segmentCDNProxy: '__SEGMENT_CDN_PROXY__',
  pageData: '"__PAGE_DATA__"',
};

let extractedConfig: ExtractedConfig | undefined;

const extractValue = <T extends keyof ExtractedConfig>(
  parsed: Record<string, any> | undefined,
  jsonKey: T,
): ExtractedConfig[T] => {
  const extracted = parsed ? parsed[jsonKey] : null;
  const placeholder = placeholders[jsonKey] || null;
  return extracted && extracted !== placeholder ? extracted : '';
};

export const extractCloudPortalSettings = memoize(() => {
  if (!extractedConfig) {
    const element = document.getElementById(CLOUD_PORTAL_SETTINGS_ELEMENT_ID);
    let parsed;

    if (element) {
      try {
        parsed = JSON.parse(element.innerHTML);
      } catch (e) {
        console.error('Invalid cloud settings configuration JSON.');
      }
    }

    extractedConfig = {
      recaptchaSiteKey: extractValue(parsed, 'recaptchaSiteKey'),
      segmentWriteKey: extractValue(parsed, 'segmentWriteKey'),
      segmentCDNProxy: extractValue(parsed, 'segmentCDNProxy'),
      pageData: extractValue(parsed, 'pageData'),
    };
  }

  return extractedConfig;
});
